<template>
    <!-- HORIZONTAL LAYOUT -->
    <vx-card title="Update Work Order">
        <div class="vx-row mb-6" style="width: 50%">
            <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left"
                @click="handleBack()">Back</vs-button>
        </div>
        <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-base">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Work Order ID </span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-input class="w-full" v-model="code" disabled />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Work Order Description</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-input class="w-full" v-model="description" />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Bill Of Material ID</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <multiselect class="selectExample" v-model="selectedBom" :options="optionBom" :multiple="false"
                            :filterable="true" :allow-empty="true" :group-select="true" :max-height="100" :limit="3"
                            :internal-search="false" placeholder="Type to search" :select-label="''"
                            :deselect-label="''" :searchable="true" track-by="id" label="Code" @select="handleBoM()" />
                    </div>
                </div>

                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Warehouse</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <multiselect class="selectExample" v-model="selectedWarehouse" :options="optionWarehouse"
                            :multiple="false" :allow-empty="false" :group-select="false" :max-height="100" :limit="3"
                            placeholder="Type to search" :select-label="''" :deselect-label="''" :searchable="true"
                            :custom-label="customLableWarehouse" />
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Request Date</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <!-- <vs-input v-model="requestDate" class="w-full" type="date" name="requestDate"
                            :min="now"></vs-input> -->
                        <datepicker name="date" :inline="false" format="yyyy-MM-dd" v-model="requestDate"
                            placeholder="Select Date" :cleared="() => {
                                requestDate = null
                            }">
                        </datepicker>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Execution Date</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <datepicker name="date" :inline="false" format="yyyy-MM-dd" v-model="executionDate"
                            placeholder="Select Date" :disabledDates="disabledDates">
                        </datepicker>
                        <!-- <vs-input v-model="executionDate" class="w-full" type="date" name="executionDate"
                            :min="minExecutionDate"></vs-input> -->
                    </div>
                </div>
            </div>

            <div class="vx-col sm:w-1/2 w-full mb-base">
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span>Note</span>
                    </div>
                    <div class="vx-col sm:w-2/5 w-full">
                        <vs-textarea v-model="note" class="w-full" type="date" name="note"></vs-textarea>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/4 w-full">
                        <label>Attachment
                            <small style="color: red">(only: jpg, jpeg, pdf, png)</small></label>
                    </div>
                    <div class="vx-col sm:w-3/4 w-full">
                        <div class="vx-col w-1/2">
                            <!-- only accept "jpg", "jpeg", "pdf", "doc", "docx", "png" , "xlsx", "xls"-->
                            <input id="fileInput" name="file" class="w-full inputx" type="file" ref="file"
                                multiple="multiple" accept=".jpg, .jpeg, .png, .pdf" />
                        </div>
                    </div>
                </div>
                <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
                    <div class="vx-col sm:w-1/4 w-full">
                        <span></span>
                    </div>
                    <div class="vx-col sm:w-3/4 w-full">
                        <div class="vx-col sm:w-4/5 w-full">
                            <vs-button class="mr-3 mb-2" @click="handleAttachment">Add Attachment</vs-button>
                        </div>
                    </div>
                </div>
                <!-- display: block -->
                <vs-divider style="width: 60%; margin-left: 30%">
                    List Attachment
                </vs-divider>
                <div class="vx-row mb-3 mt-6" style="width: 60%; margin-left: 30%">
                    <table class="vs-table vs-table--tbody-table">
                        <template>
                            <tr class="tr-values vs-table--tr tr-table-state-null selected" v-bind:key="tr.NameFile"
                                v-for="(tr, i) in fileAttachment">
                                <td class="td vs-table--td">{{ tr.NameFile }}</td>
                                <td class="td vs-table--td">
                                    <vx-tooltip text="Show" v-if="tr.PathFile != ''">
                                        <vs-button type="line" icon-pack="feather" icon="icon-eye"
                                            @click.stop="handleShowAttachment(tr)" />
                                    </vx-tooltip>
                                </td>

                                <td class="td vs-table--td">
                                    <template>
                                        <vx-tooltip text="Delete">
                                            <vs-button type="line" icon-pack="feather" icon="icon-trash"
                                                @click.stop="handleDeleteAttachment(tr.id, i)" />
                                        </vx-tooltip>
                                    </template>
                                </td>
                            </tr>
                        </template>
                    </table>
                </div>
            </div>
        </div>
        <h3><b>Parent (SKU Result)</b></h3>
        <hr />
        <br />
        <div class="vx-row">
            <div class="vx-col sm:w-1/1 w-full mb-base m-10">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="55%">SKU Description</th>
                            <!-- <th width="30%">SKU Name</th> -->
                            <th width="15%">HU</th>
                            <th width="15%">Qty [SKU]</th>
                            <th width="15%">Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" :value="`${itemParent.sku_code} ${itemParent.name}`"
                                    disabled />
                            </td>
                            <!-- <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" v-model="itemParent.name" disabled />
                            </td> -->
                            <td class="td vs-table--td" style="padding-right: 5px">
                                <vs-input class="w-full" v-model="itemParent.unit" disabled />
                            </td>
                            <td class="td vs-table--td" style="padding-right: 5px">
                                <vs-input class="w-full" type="number" v-model="itemParent.qty" :min="1" />
                            </td>
                            <td class="td vs-table--td" style="padding-right: 10px">
                                <vs-input class="w-full" v-model="itemParent.price" disabled />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <h3><b>Child (SKU Source)</b></h3>
        <hr />
        <br />

        <div class="vx-row">
            <div class="vx-col sm:w-1/1 w-full mb-base m-10">
                <table width="100%" class="vs-table vs-table--tbody-table">
                    <thead class="vs-table--thead">
                        <tr>
                            <th width="50%">SKU Description</th>
                            <!-- <th width="30%">SKU Name</th> -->
                            <th width="25%">HU</th>
                            <th width="25%">Qty [SKU]</th>
                            <!-- <th width="5%"></th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(child, index) in itemLines" :key="index" style="padding-bottom: 5px">
                            <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full"
                                    :value="`${itemLines[index].sku_code} ${itemLines[index].name}`" disabled />
                            </td>
                            <!-- <td class="td vs-table--td" style="padding: 5px">
                                <vs-input class="w-full" v-model="itemLines[index].name" disabled />
                            </td> -->
                            <td class="td vs-table--td" style="padding-right: 5px">
                                <vs-input class="w-full" v-model="itemLines[index].unit" disabled />
                            </td>
                            <td class="td vs-table--td" style="padding-right: 10px">
                                <vs-input class="w-full" v-model="itemLines[index].qty" disabled />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <br>
        <div class="vx-row mb-6" style="width: 50%">
            <div class="vx-col sm:w-1/1 w-full">
                <div style="
                    position: absolute;
                    left: 50%;
                    -ms-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                ">
                    <vs-button class="ml-4 mt-2" color="primary" v-on:click="SubmitForm()">Submit</vs-button>
                    <vs-button class="ml-2 mt-2" type="border" color="danger" v-on:click="handleBack()">Back</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<!-- NANTI MODIFIKASI MANAJEMEN FILE ATTACHMENT -->

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
    props: {
        title: {
            type: String,
        },
        action: {
            type: String,
        },
    },
    components: {
        Datepicker
    },
    mounted() {
        this.getData(this.$route.params.id);
        this.getBillOfMaterial();
        this.getWarehouse();
    },
    computed: {
        disabledDates() {
            return {
                to: moment(this.executionDate).toDate()
            };
        },
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
    watch: {
        requestDate: function (val) {
            this.minExecutionDate = moment(val).add(1, "days").format("YYYY-MM-DD");
            this.executionDate = moment(val).add(1, "days").format("YYYY-MM-DD");
        },
        "itemParent.qty": function (val) {
            if (this.itemParent.qty > 0) {
                // if (val % 2 == 0) {
                this.itemLines = this.itemLines.map((el) => {
                    return {
                        id: el.id,
                        item_unit_id: el.item_unit_id,
                        sku_code: el.sku_code,
                        name: el.name,
                        unit: el.unit,
                        qty_old: el.qty_old,
                        qty: Math.ceil((val / this.itemParent.qty_old) * el.qty_old),
                    };
                });
                // } else {
                //     val = val - 1;
                //     this.itemLines = this.itemLines.map((el) => {
                //         return {
                //             id: el.id,
                //             item_unit_id: el.item_unit_id,
                //             sku_code: el.sku_code,
                //             name: el.name,
                //             unit: el.unit,
                //             qty_old: el.qty_old,
                //             qty: Math.ceil((val / this.itemParent.qty_old) * el.qty_old),
                //         };
                //     });
                //     //then add 1 to the first element
                //     this.itemLines[0].qty = this.itemLines[0].qty + 1;
                // }
            } else {
                this.itemLines = this.itemLines.map((el) => {
                    return {
                        id: el.id,
                        item_unit_id: el.item_unit_id,
                        sku_code: el.sku_code,
                        name: el.name,
                        unit: el.unit,
                        qty_old: el.qty_old,
                        qty: 0,
                    };
                });
            }
        },
    },
    data: () => ({
        optionItem: [],
        itemLines: [
            {
                id: null,
                item_unit_id: null,
                sku_code: "",
                name: "",
                unit: "",
                qty: 0,
                qty_old: 0,
            },
        ],
        itemParent: {
            item_unit_id: null,
            sku_code: "",
            name: "",
            unit: "",
            qty: 0,
            qty_old: 0,
            price: 0
        },
        optionBom: [],
        selectedBom: null,
        selectedWarehouse: null,
        optionWarehouse: [],
        requestDate: moment().format("YYYY-MM-DD"),
        minExecutionDate: moment().add(1, "days").format("YYYY-MM-DD"),
        executionDate: moment().add(1, "days").format("YYYY-MM-DD"),
        start: 1,
        end: 0,
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        code: "",
        description: "",
        note: "",
        attachment: "",
        fileAttachment: [],
        newFileAttachment: [],
        IDAttachment: [],
        now: moment().add(0, "days").format("YYYY-MM-DD"),
        IDs: null,
        isDisabled: true,
        allowedExtension: ["jpg", "jpeg", "png", "pdf"],
        workOrderStatus: 1,
    }),
    methods: {
        handleBack() {
            this.$router.back();
        },
        SubmitForm() {
            if (!this.description) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please input Work Order Description",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return
            }

            if (!this.selectedBom || !this.optionBom.length) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Bill of Material",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return;
            }
            if (!this.selectedWarehouse) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Warehouse",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return;
            }

            if (this.itemLines.length == 0) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Item Line first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return true;
            }

            if (this.fileAttachment.some(a => !this.allowedExtension.includes(a.NameFile.split(".").at(-1).toLowerCase()))) {
                this.$vs.notify({
                    title: "Error",
                    text: "Invalid attachments extention",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
                return true;
            }

            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to update this data?",
                accept: () => {
                    this.$vs.loading();
                    this.$http
                        .post("api/wms/v1/work-order/update", {
                            id: this.IDs,
                            note: this.note,
                            description: this.description,
                            item_unit_id: this.itemParent.item_unit_id,
                            sku_code: this.itemParent.sku_code,
                            name: this.itemParent.name,
                            unit: this.itemParent.unit,
                            quantity: Number(this.itemParent.qty),
                            default_quantity: Number(this.itemParent.qty_old),
                            price: Number(this.itemParent.price),
                            warehouse_id: this.selectedWarehouse.id,
                            warehouse_name: this.selectedWarehouse.name,
                            item_bill_of_material_id: this.selectedBom.id,
                            item_bom_code: this.selectedBom.Code,
                            request_date: this.requestDate,
                            execution_date: this.executionDate,
                            item_sku: this.itemLines.map((el) => {
                                return {
                                    id: el.id,
                                    item_unit_id: el.item_unit_id,
                                    sku_code: el.sku_code,
                                    sku_name: el.name,
                                    unit_name: el.unit,
                                    qty: Number(el.qty),
                                    quantity_real: Number(el.qty_old),
                                };
                            }),
                            status: this.workOrderStatus,
                        })
                        .then((resp) => {
                            // console.log(resp);
                            if (resp.code == 200) {
                                this.$vs.notify({
                                    title: "Success",
                                    text: "Work Order has been updated",
                                    color: "success",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-check",
                                });
                                this.IDs = resp.data;
                                if (this.newFileAttachment.length) {
                                    this.handleSubmitAttachment();
                                } else {
                                    this.handleBack()
                                }
                            } else {
                                this.attachment = [];
                                this.$vs.notify({
                                    title: "Error",
                                    text: resp.message,
                                    color: "danger",
                                    position: "top-right",
                                    iconPack: "feather",
                                    icon: "icon-check",
                                });
                            }

                            this.$vs.loading.close();
                        })
                        .catch((error) => {
                            this.$vs.loading.close();
                            console.log(error);
                        });
                }
            });
        },
        mappingAttachment() {
            this.adjustment.Attachment.map((v) => {
                this.fileAttachment.push({
                    ListID: v.ListID,
                    NameFile: v.NameFile,
                    PathFile: v.PathFile,
                });
            });
        },
        handleAttachment() {
            if (this.$refs.file.files.length > 0) {
                this.file = this.$refs.file.files;
                // this.fileAttachment = [];
                for (let i = 0; i < this.$refs.file.files.length; i++) {
                    // this.uploadData(i);
                    this.fileAttachment.push({
                        NameFile: this.file[i].name,
                        File: this.file[i],
                    });
                    this.newFileAttachment.push({
                        NameFile: this.file[i].name,
                        File: this.file[i],
                    });
                }
                // this.handleSubmitAttachment();
                document.querySelector("#fileInput").value = "";
            } else {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select file to import.",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                });
            }
        },
        handleShowAttachment(file) {
            let objectURL;
            // console.log(file)
            objectURL = file.PathFile;

            // link.download = file.name; // this name is used when the user downloads the file
            var href = objectURL;
            window.open(href, "_blank").focus();
        },
        handleDeleteAttachment(data, index) {
            this.$vs.loading();
            this.$http
                .delete("api/wms/v1/work-order/delete-attachment", {
                    params: {
                        id: data,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            title: "Success",
                            text: "Data deleted successfully",
                            color: "success",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                        this.fileAttachment = this.fileAttachment.filter((r, i) => {
                            return i != index;
                        });
                        this.IDAttachment = this.IDAttachment.filter((r, i) => {
                            return r != data.id;
                        });
                    }
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        handleSubmitAttachment() {
            this.$vs.loading();
            if (this.newFileAttachment.length > 0) {
                const formData = new FormData();
                for (let index = 0; index < this.newFileAttachment.length; index++) {
                    formData.append("attachments[]", this.newFileAttachment[index].File);
                }
                formData.append("work_order_id", this.IDs);

                this.$http
                    .post("api/wms/v1/work-order/create-attachment", formData)
                    .then((resp) => {
                        if (resp.code == 200) {
                            // for (
                            //     let index = 0;
                            //     index < resp.data.attachment_id.length;
                            //     index++
                            // ) {
                            //     this.IDAttachment.push(resp.data.attachment_id[index]);
                            // }
                            // this.$vs.notify({
                            //     color: "success",
                            //     title: "Success",
                            //     text: resp.message,
                            //     position: "top-right",
                            //     iconPack: "feather",
                            //     icon: "icon-check-circle",
                            // });
                            // this.newFileAttachment = [];
                            this.handleBack();
                            this.$vs.loading.close();
                        } else {
                            this.$vs.notify({
                                color: "danger",
                                title: "Failed",
                                text: resp.message,
                                position: "top-right",
                                iconPack: "feather",
                                icon: "icon-x-circle",
                            });
                            this.$vs.loading.close();
                        }
                    })
                    .catch((error) => {
                        this.$vs.loading.close();
                        console.log(error);
                    });
            }
        },
        getBillOfMaterial() {
            this.$vs.loading();
            this.$http
                .get("api/wms/v1/work-order/item-bill-of-material", {
                    params: {
                        length: null,
                        order: "id",
                        sort: "asc",
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        if (resp.data.records) {
                            this.optionBom = resp.data.records;
                            if (this.optionBom.length > 0) {
                                // this.selectedBom = this.optionBom[0];
                            } else {
                                this.optionBom = [];
                                this.selectedBom = {};
                            }
                        } else {
                            this.optionBom = [];
                            this.selectedBom = {};
                        }
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        getWarehouse() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/master/warehouses", {
                    params: {
                        length: null,
                        order: "name",
                        sort: "asc",
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        if (resp.data.records) {
                            this.optionWarehouse = resp.data.records;
                            if (this.optionWarehouse.length > 0) {
                                // this.selectedWarehouse = this.optionWarehouse[0];
                            } else {
                                this.optionWarehouse = [];
                                this.selectedWarehouse = {};
                            }
                        } else {
                            this.optionWarehouse = [];
                            this.selectedWarehouse = {};
                        }
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        customLableWarehouse({ code, name }) {
            return `${code} - ${name}`;
        },
        handleBoM() {
            console.log("selectedBom", this.selectedBom);
            if (this.selectedBom) {
                this.itemParent = [];
                this.itemParent = {
                    item_unit_id: this.selectedBom.ItemUnitID,
                    sku_code: this.selectedBom.SkuCode,
                    name: this.selectedBom.Name,
                    unit: this.selectedBom.Unit,
                    qty: this.selectedBom.Quantity,
                    qty_old: this.selectedBom.Quantity,
                    price: this.selectedBom.Price
                };

                this.itemLines = [];
                this.itemLines = this.selectedBom.ItemBillOfMaterialLines.map((el) => {
                    return {
                        item_unit_id: el.ItemUnitID,
                        sku_code: el.SkuCode,
                        name: el.Name,
                        unit: el.Unit,
                        qty: el.Quantity,
                        qty_old: el.Quantity,
                    };
                });
            } else {
                this.isDisabled = true;
            }
        },
        getData(id) {
            this.$vs.loading();
            this.$http
                .get("api/wms/v1/work-order/detail", {
                    params: {
                        id: id,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.code = resp.data.code;
                        this.IDs = resp.data.id;
                        this.description = resp.data.description;
                        this.note = resp.data.note;
                        this.requestDate = resp.data.request_date;
                        this.executionDate = resp.data.execution_date;
                        this.selectedBom = {
                            id: resp.data.item_bill_of_material_id,
                            Code: resp.data.item_bom_code,
                        };
                        this.selectedWarehouse = {
                            id: resp.data.warehouse_id,
                            name: resp.data.warehouse_name,
                            code: resp.data.code
                        };
                        this.workOrderStatus = resp.data.status

                        this.itemParent = [];
                        this.itemParent = {
                            item_unit_id: resp.data.item_parent.item_unit_id,
                            sku_code: resp.data.item_parent.sku_code,
                            name: resp.data.item_parent.name,
                            unit: resp.data.item_parent.unit,
                            qty: resp.data.item_parent.quantity,
                            qty_old: resp.data.item_parent.quantity_old,
                            price: resp.data.item_parent.price
                        };

                        if (resp.data.attachment_data) {
                            resp.data.attachment_data.forEach((el) => {
                                if (el.deleted == "") {
                                    this.fileAttachment.push({
                                        id: el.id,
                                        NameFile: el.file_name,
                                        PathFile: el.file_url,
                                    });
                                }
                            });
                        }

                        this.itemLines = [];
                        if (resp.data.work_order_lines) {
                            resp.data.work_order_lines.forEach((el) => {
                                this.itemLines.push({
                                    id: el.id,
                                    item_unit_id: el.item_unit_id,
                                    sku_code: el.sku_code,
                                    name: el.name,
                                    unit: el.unit,
                                    qty: el.quantity,
                                    qty_old: el.quantity_old,
                                });
                            });
                        }
                    }
                    this.$vs.loading.close();
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
    },
};
</script>
